import '../css/app.css';
import './bootstrap';
import '@stripe/stripe-js';
import * as Sentry from '@sentry/browser';
import Layout from './Layouts/Layout.vue';
import Notifications from 'notiwind';
import type { DefineComponent } from 'vue';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
if (['staging', 'production'].includes(import.meta.env.APP_ENV)) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()], // only works inside browser, which means that `npm run build` fails.
    // release: '' // automatically first 12 characters of git commit hash. tk: see if this can be the Jira Ticket instead...

    replaysOnErrorSampleRate: 0.1, // capture 10% of all sessions with an error. When
    replaysSessionSampleRate: 0.1, // Capture 10% of all sessions

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
createInertiaApp({
  progress: {
    color: '#a855f7', // used to be '#4B5563'; now purple-500
    showSpinner: true,
  },
  resolve: (name) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'));
    page.then((module) => {
      module.default.layout = module.default.layout ??= Layout;
    });
    return page;
  },
  // @ts-expect-error because it's too much to fix a boilerplate file.
  setup({ el, App, props, plugin }) {
    return (
      createApp({ render: () => h(App, props) })
        .use(plugin)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .use(ZiggyVue, (window as any).Ziggy)
        .use(Notifications)
        .mount(el)
    );
  },
  title: (title) => `${title} - ${appName}`,
});
